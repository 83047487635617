.home-categories{
    background-color: $color-bg;
    padding-top: 100px;
    padding-bottom: 50px;
    @include mq($until: lg) {

    }
    &__heading{
        text-align: center;
        font-family: $font-family-primary;
        font-weight: $fw-regular;
        font-size: rem(31.95px);
        margin-bottom: 25px;
        color: $color-font-gray;
    }
    &__wrapper{
       display: flex;
       justify-content: center;
       align-items: start;
    }
    &__ornament{
        padding-top: 40px;
        @include mq($until: sm) {
            display: none;
        }
        &--left{
            margin-right: 50px;
            @include mq($until: md) {
                margin-right: 20px;

            }
        }
        &--right{
            margin-left: 50px;
            transform: scaleX(-1);
            @include mq($until: md) {
                margin-left: 20px;
            }
        }
    }
    &__inner-wrapper{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 15px;
        justify-content: space-between;
        align-items: flex-start;
        @include mq($until: md) {
            grid-gap: 10px;
            grid-template-columns: repeat(5, 90px);
        }
        @include mq($until: sm) {
            display: flex;
            grid-gap: 15px;
            flex-wrap: wrap;
            justify-content: center;
            grid-template-columns: none;
            &>a{
                flex-basis: 120px;
            }
        }

    }
    &__category-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 120px;
        &:hover > .home-categories__image{
            filter: none;
        }
        @include mq($until: md) {
            max-width: none;
        }
        
    }
    &__image{
        filter: sepia(100%);
        transition: .5s;
    }
    &__category-name{
        text-align: center;
        text-transform: uppercase;
        font-family: $font-family-secondary;
        font-size: rem(21px);
        @include mq($until: md) {
            font-size: rem(18px);
        }
        @include mq($until: sm) {
            font-size: rem( 21px);
        }
    }
}
.single{
    display: grid;
    grid-template-columns: 0.8fr 1.2fr;
    grid-gap: 80px;
    margin-top: 50px;
    @include mq($until: lg) {
        grid-gap: 70px;
    }
    @include mq($until: md) {
        margin-top: 0;
        grid-gap: 60px;
    }
    @include mq($until: sm) {
        grid-template-columns: 1fr;
        grid-gap: 50px;
    }
    &--news{
        grid-template-columns: 1.2fr 0.8fr;
        @include mq($until: sm) {
            grid-template-columns: 1fr;
        }
    }
    &--product{
        grid-template-columns: 55% 45%;
        grid-gap: 0;
        @include mq($until: sm) {
            grid-template-columns: 100%;
        }
        & .single__left-col{
            padding-right: 50px;
            @include mq($until: sm) {
                padding-right: 0;
            }
        }
    }

    &__heading{
        font-size: rem(36px);
        margin-bottom: 20px;
        padding-bottom: 30px;
        position: relative;
        &:after{
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $color-extra;
        }
    }
    .specification{
        margin-bottom: 20px;
        padding-bottom: 20px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        @include mq($until: sm) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            margin-bottom: 30px;
        }
        &:after{
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $color-extra;
        }
        li{
          margin: 5px 0;
          padding: 0;
          font-size: rem(20.96px);
          line-height: rem(33px);
          &:before{
            display: none;
          }
          &.separator{
            margin-left: 10px;
            margin-right: 10px;
            @include mq($until: sm) {
                display: none;
            }
          }
        }
        &__price{
            font-weight: $fw-semi-bold;
        }
    }
    &__text{
        display: block;
        margin-bottom: 60px;
        @include mq($until: sm) {
            margin-bottom: 40px;
        }
    }
    &__button{
        padding: 10px 60px;
        text-transform: uppercase;
        border: 1px solid $color-extra;
        &:hover{
            border: 1px solid $color-main;
        }
        @include mq($until: sm) {
            width: 100%;
        }
    }
    &__left-col{
        @include mq($until: sm) {
            order: 2;
        }
        .single__heading{
            display: block;
            @include mq($until: sm) {
                display: none;
            }
        }
        .specification{
            display: flex;
            @include mq($until: sm) {
                display: none;
            }
        }
    }

    &__right-col{
        @include mq($until: sm) {
            order: 1;
        }
        .single__heading{
            display: none;
            @include mq($until: sm) {
                display: block;
            }
        }
        img, a{
            border-radius: 10px;
            @include mq($until: sm) {
                margin-top: 30px;
            }
        }
        .specification{
            display: none;
            @include mq($until: sm) {
                display: flex;
            }
        }
    }
    .torty__slider{
        position: relative;
        width: 100%;
        @include mq($until: sm) {
            margin-bottom: 30px;
        }
        img, a{
            margin-top: 0;
        }
        .arrow-slide{
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            z-index: 5;
            width: 35px;
            height: 35px; 
            cursor: pointer;
            border-radius: 5px;
            background-color: #fff;
            @include mq($until: md) {
                width: 25px;
                height: 25px;                
            }

            &--prev{
                bottom: 20px;
                right: 65px;  
                @include mq($until: md) {
                    right: 50px;              
                }              
            }
            &--next{
                bottom: 20px;
                right: 20px;
            }

        }
    }
}


.gallery-list{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-column-gap: 30px;
  grid-row-gap: 20px;
  @include mq($until: md){
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 30px;
  }
  @include mq($until: sm){
    grid-template-columns: repeat(2,1fr);
  }
  @include mq($until: xs){
    grid-template-columns: 75vw;
    justify-content: center;
  }
  &__link{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: .3s;
    &:hover {
      .gallery-list__name{
        color: $color-main;
      }
        transform: translateY(-5px);
    }
  }
  &__image{
    &-wrapper{
      overflow: hidden;
      display: flex;
      border-radius: 10px;
      background-position: center center;
      background-size: cover;
      width: 100%;
      height: 17vw;
      @include mq($until: md){
        height: 24vw;
      }
      @include mq($until: sm){
        height: 38vw;
      }
      @include mq($until: xs){
        height: 70vw;
      }
    }
    transition: transform .3s ease-in-out;
  }
  &__name{
    font-weight: 600;
    margin-top: 10px;
    text-align: center;
    transition: color .3s ease-in-out;
    font-size: rem(20.96px);
    line-height: rem(33px);
    @include mq($until: xs){
      margin-top: 15px;
      font-size: rem(24.96px);
    }
  }
  &__item{
      margin: 0;
      padding: 0;
      &:before{
          display: none;
      }
  }
}

.news{
    padding-top: 50px;
    padding-bottom: 50px;
    overflow: hidden;
    &__card{
        display: grid;
        grid-gap: 35px;
        justify-content: center;
        align-items: flex-start;
        margin: 100px 0;
        grid-template-columns: 320px 1fr;
        @include mq($until: lg) {
            grid-template-columns: 270px 1fr;
        }
        @include mq($until: md) {
            grid-template-columns: 1fr;
            grid-template-rows: 300px auto;
        }
        &:first-child{
            margin-top: 0;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
    &__image-wrapper{
        height: 100%;
    }
    &__image{
        height: 200px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 5px;
        @include mq($until: md) {
            height: 100%;
        }
    }
    &__text{
        margin-bottom: 20px;
    }
}
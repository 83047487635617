// =========================
// Fonts
// =========================

 @font-face {
     font-family: 'Alegreya';
     font-weight: 700;
     src: url('../../fonts/Alegreya-Bold.ttf');
 }

 @font-face {
    font-family: 'Alegreya';
    font-weight: 400;
    src: url('../../fonts/Alegreya-Regular.ttf');
}

@font-face {
    font-family: 'Raleway';
    font-weight: 500;
    src: url('../../fonts/Raleway-Medium.ttf');
}

.columns-3 {
    grid-template-columns: repeat(3, 1fr);
    .gallery__link {
        height: 23vw;
        @include mq($until: xs) {
            height: 87vw;
        }
    }
}

.columns-4 {
    grid-template-columns: repeat(4, 1fr);
    .gallery__link {
        height: 18vw;
        @include mq($until: xs) {
            height: 81vw;
        }
    }
}

.columns-2 {
    grid-template-columns: repeat(2, 1fr);
    .gallery__link {
        height: 37vw;
        @include mq($until: xs) {
            height: 81vw;
        }
    }
}

.gallery {
    display: grid;
    grid-gap: 30px;
    @media screen and (max-width: 991px) {
        grid-gap: 20px;

    }
    @media screen and (max-width: 575px) {
        grid-template-columns: auto;

    }

    .gallery__link {
        transition: transform .3s;
        display: inline-block;
        overflow: hidden;
        background-size: cover;
        background-position: center center;
        &:hover {
            transform: translateY(-5px);
            .gallery__img {
                box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.4);
            }
        }
    }

    &__img {
        width: 100%;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
        transition: box-shadow .3s;
    }
}

.gallery__link{
    border-radius: 10px;
}


.photo{
    height: 22vw;
    border-radius: 5px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    @include mq($until: md) {
        height: 400px;
        width: 100%;
        border-radius: 5px;
    }
}
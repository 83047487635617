.aside-menu{
    padding: 30px;
    background-size: cover;
    background-position: center center;
    border-radius: 10px;
    &__menu-link{
        font-family: $font-family-secondary;
        color: $color-font-light;
        font-size: rem(22px);
    }
    &__menu-item{
        margin: 20px 0;
        &:first-child{
            margin-top: 0;
        }
        &:last-child{
            margin-bottom: 0;
        }
        &:before{
            display: none;
        }
    }
    &__menu-heading{
        color: $color-extra;
        font-size: rem(27px);
        margin-bottom: 30px;
    }
}

.checkbox{
    display: inline-flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &__btn{
        padding: 8px 25px;
        &:first-child{
            margin-right: 10px;
        }
        &:last-child{
            margin-left: 10px;
        }
        &--yes{
            background-color: $color-extra2;
            color: $color-main;
        }
        &--no{
            background-color: $color-main;
            color: $color-extra2;
        }
    }
    &__input{
        visibility: hidden;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked{
            & ~ .checkbox__btn{
                &--yes{
                    background-color: $color-main;
                    color: $color-extra2;
                }
                &--no{
                    background-color: $color-extra2;
                    color: $color-main;
                }
            }
        }
    }
}

.order-page{
    background-size: cover;
    background-position: center center;
    padding-top: 10vw;
    &__title{
        padding-top: 40px;
        text-align: center;
        text-transform: uppercase;
        font-size: rem(30px);
    }
    &__product-title{
        font-size: rem(26px);
        font-family: $font-family-secondary;
        text-transform: uppercase;
    }
    &__form{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 40px;
        @include mq($until: lg) {
            grid-template-columns: 1fr;
        }
    }
    &__form-column{
        padding: 40px;
        background-color: $color-extra2;
        border-radius: 10px;
        display: grid;
        grid-gap: 20px;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
        &--first{
            border: 15px solid $color-font-light;
        }   
    }
    &__label{
        font-size: rem(18px);
        font-family: $font-family-secondary;
        margin-bottom: 8px;
        margin-right: 8px;
    }
    &__input{
        border-radius: 5px;
        background-color: #fff;
        border: none;
        padding: 8px 15px;
        transition: .2s;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
        &--secondary{
            background-color: $color-bg;
        }
        &:focus{
            outline: none;
            box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
            background-color: #fff;
        }
    }
    .responsiv-uploader-fileupload{
        border-radius: 5px;
        position: relative;
        &:hover{
            &.style-file-single{
                box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
                background-color: #fff;
            }            
        }
        &.style-file-single{
            border: none;  
            background-color: $color-bg; 
            transition: .2s;
        }
        .upload-empty-message{
            display: flex;
            justify-content: center;
            font-size: rem(18px);
            font-family: $font-family-secondary;
            .text-muted{
                font-size: rem(18px);
            }
        }
        .button{
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            padding: 0;
            margin: 0;
        }
    }
    &__input-wrapper{
        display: flex;
        flex-direction: column;
        &--hidden{
            // height: 0;
            // opacity: 0;
            // visibility: hidden;
        }
    }
    &__input-inner-wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px;
        &--date{
            grid-template-columns: 1.5fr 0.5fr;
        }
    }
    &__form-submit{
        background-color: $color-main;
        text-transform: uppercase;
        text-align: center;
        transition: .3s;
        border: 1px solid $color-main;
        padding: 8px 15px;
        color: $color-extra2;
        &:hover{
            background-color: $color-extra2;
            color: $color-main;
        }
    }
}
.home-about{
    background-color: $color-bg;
    padding-top: 7vw;
    padding-bottom: 7vw;
    overflow: hidden;
    &__wrapper{
        display: grid;
        grid-template-columns: 1fr 660px;
        justify-content: center;
        align-items: stretch;
        @include mq($until: xxl) {
            grid-template-columns: 1fr 600px;
        }
        @include mq($until: xl) {
            grid-template-columns: 1fr 560px;
        }
        @include mq($until: lg) {
            grid-template-columns: 1fr 500px;
        }
        @include mq($until: md) {
            grid-template-columns: 1fr 400px;
        }
        @include mq($until: sm) {
            grid-template-columns: 1fr;
        }
    }
    &__left-col{
        padding: 30px 80px 30px 0;
        @include mq($until: xl) {
            padding-right: 40px;
        }
        @include mq($until: lg) {
            padding-right: 0;
        }
        @include mq($until: sm) {
            padding-bottom: 80px;
        }
        @media (max-width: 420px) {
            padding-bottom: 50px;
        }
    }
    &__title{
        font-size: rem(29.36px);
        font-weight: $fw-bold;
        line-height: rem(29.36px);
    }
    &__subtlte p{
        font-size: rem(29.36px);
        font-weight: $fw-regular;
        line-height: rem(33px);
        font-family: $font-family-secondary;
        margin-bottom: 20px;
    }
    &__content p{
        font-size: rem(20.96px);
        font-weight: $fw-regular;
        line-height: rem(33px);
    }
    &__right-col{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        & > img{
            position: absolute;
            min-width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @include mq($until: md) {
                transform: translate(0, -50%);
                min-width: 150%;
                left: -20px;
            }
            @include mq($until: sm) {
                min-width: 100%;
                left: calc(50% - 20px);
                transform: translate(-50%, -50%);
                top: calc(50% - 10vw);
            }
            @include mq($until: xs) {
                min-width: 110%;          
            }
            @media (max-width: 420px) {
                // min-width: 105%;
            }
        }
        @include mq($until: sm) {
            min-height: 300px;
            overflow-y: hidden
        }
        @include mq($until: xs) {
            overflow: hidden;
        }
    }
    &__content-right p{
        font-size: rem(32px);
        font-weight: $fw-bold;
        color: $color-font-light;
        line-height: rem(39px);
        font-family: $font-family-secondary;
        position: relative;
        padding: 0 100px 0 160px;
        text-align: center;
        @include mq($until: xxl) {
            // padding: 0 5vw 0 8vw;
        }
        @include mq($until: xl) {
            // padding: 0 7vw 0 10vw;
        }
        @include mq($until: lg) {
            padding: 0 80px 0 140px;
            font-size: rem(28px);
        }
        @include mq($until: md) {
            font-size: rem(30px);
            padding-left: 140px;
            padding-right: 0;
        }
        @include mq($until: sm) {
            padding: 0 100px 40px 130px;
        }
        @include mq($until: xs) {
            font-size: rem(28px);
            padding: 0 70px 10vw 75px;
        }
        @media (max-width: 420px) {
            font-size: rem(22px);
            line-height: rem(32px);
            padding: 0 50px 20vw 45px;
            // padding-bottom: 25vw;
            // padding-left: 15vw;
        }
    }
}
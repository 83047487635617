.home-order{
    position: relative;
    // min-height: 350px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-size: cover;
    // background-position: 60% right;
    margin-bottom: 50px;
    // @include mq($until: xl) {
    //     background-position: 50% 100%;
    // }
    // @include mq($until: md) {
    //     min-height: 450px;
    // }
    // @include mq($until: sm) {
    //     background-position: 55% 100%;
    // }
    @include mq($until: lg) {
        height: 500px;
    }
    @include mq($until: sm) {
        height: 400px;
    }
    @include mq($until: xs) {
        height: 250px;
    }
    &__bg{
        width: 100%;
        @include mq($until: lg) {
            position: absolute;
            width: auto;
            top: 0;
            right: -20vw;
            height: 100%;
            max-width: none;
        }
        @include mq($until: md) {
            right: -40vw;
        }
        @include mq($until: sm) {
            // right: -60vw;
        }
        @include mq($until: xs) {
            right: -30vw;
            opacity: 0.6;
        }
        @media (max-width: 350px) {
            right: -50vw;
        }
    }
    .wave{
        position: absolute;
        top: -20px;
        left: 0;
        z-index: 1;
        width: 100%;
        height: auto;
        fill: $color-bg;
        @include mq($until: sm) {
            width: 200%;
            left: auto;
            right: 0;
            top: -13px;
        }
        
    }
    &__content{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        z-index: 1;
    }
    &__heading{
        font-size: rem(48px);
        max-width: 500px;
        margin-bottom: 40px;
        @include mq($until: md) {
            font-size: rem(42px);
        }
        @include mq($until: sm) {
            margin-bottom: 25px;
            padding-right: 20px;
        }
        @include mq($until: xs) {
            font-size: rem(38px);
            margin-bottom: 15px;
        }
    }
}


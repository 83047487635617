.footer-addresses{
    padding-top: 140px;
    padding-bottom: 140px;
    background-color: $color-bg;
    @include mq($until: md) {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    @include mq($until: sm) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    &__heading{
        font-size: rem(34px);
        margin-bottom: 40px;
        @include mq($until: sm) {
            margin-bottom: 30px;
        }
    }
    &__inner-wrapper{
        display: grid;
        grid-template-columns: 50% 50%;
        justify-content: center;
        align-items: flex-start;
        @include mq($until: md) {
            grid-template-columns: 100%;
        }
    }
    &__left-col{
        position: relative;
        background-position: 50% 50%;
        background-size: cover;
        height: 100%;
        border-radius: 10px;
        @include mq($until: md) {
            order: 2;
            height: 400px;
        }
    }
    &__right-col{
        padding-left: 50px;
        border-radius: 10px;
        @include mq($until: md) {
            padding-left: 30px;
        }
        @include mq($until: sm) {
            padding-left: 0;
        }
        @include mq($until: sm) {
            order: 1;
        }
    }
    &__inner-heading{
        font-size: rem(21px);
        margin: 30px 0;
        @include mq($until: sm) {
            margin-top: 25px;
            margin-bottom: 25px;
        }
    }
    &__bakery{
        display: flex;
        align-items: center;
        margin: 20px 0;
        .bakery-icon{
            fill: $color-extra;

        }
        &--alternative{
            .bakery-icon{
                fill: $color-main;
            }
        } 
    }
    &__street{
        margin-left: 20px; 
        font-size: rem(18px);
        @include mq($until: sm) {
            margin-left: 10px; 
        }
    }
    &__city{
        font-weight: $fw-bold;
        margin-left: 5px;
        font-size: rem(18px);
    }
}

.footer-company{
    padding-top: 80px;
    padding-bottom: 20px;
    background-color: #492E33;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
    position: relative;
    min-height: 200px;
    @include mq($until: lg) {
        grid-template-columns: auto;
        justify-content: center;
    }
    .wave{
        position: absolute;
        top: -40px;
        left: 0;
        z-index: 1;
        width: 100%;
        height: auto;
        fill: $color-bg;
        @include mq($until: lg) {
            top: -30px;
        }
        @include mq($until: md) {
            top: -20px;
        }
        @include mq($until: sm) {
            width: 200%;
            left: auto;
            right: 0;
            top: -13px;
        }
    }
    &__logo{
        max-width: 128px;
        @include mq($until: lg) {
            order: 2;
            margin: 0 auto;
        }
    }
    &__menu{
        display: flex;
        justify-content: center;
        align-items: center;
        @include mq($until: lg) {
            margin-bottom: 40px;
        }
        @include mq($until: md) {
            flex-wrap: wrap;

        }
    }
    &__menu-item{
        margin: 0 20px;
        padding-left: 0;
        &::before{
            display: none;
        }
        &:first-child{
            margin-left: 0;
            @include mq($until: md) {
                margin: 15px;
            }
        }
        &:last-child{
            margin-right: 0;
            @include mq($until: md) {
                margin: 15px;
            }
        }
        @include mq($until: md) {
            margin: 15px;
        }
    }
    &__menu-link{
        color: $color-extra;
        font-weight: $fw-medium;
        font-size: rem(20px);

    }
}

.footer-small{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    &__realization{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span{
        transition: color .3s ease-in-out;
      }
      &:hover span{
        color: $color-main;
      }
      img{
        margin-left: 15px;
      }
    }
}
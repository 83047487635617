.header{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    top: 0;
    width: 100%;
    position: absolute;
    z-index: 2;
    @include mq($until: md){
        align-items: flex-start;
        flex-direction: row;
        padding: 0;
    }
    @include mq($until: xs){
        flex-direction: row;
        // padding: 20px 10px;
    }
    &.sticky{

        @include mq($until: md){
            // background-color: rgba($color: $color-main, $alpha: 0.8);
            // padding: 5px 20px;
        }
        @include mq($until: xs){
            // padding: 5px 10px;
        }
        .header__nav{
            top: 1vw;
            z-index: 1;
            @include mq($until: md){
                top: 0;
            }
        }
        .header__menuLink{
            color: $color-font-light;
        }
        .header__logo{
            top: 4vw;
            @include mq($until: md){
                top: 0;
            }
        }

    }
    &--fixed{
      position: fixed;
      width: 100%;
      transition: .3s ease-in-out;
    }
    &__logo{
        position: relative;
        transition: .3s ease-in-out;
        top: 0;
        z-index: 1;
        @include mq($until: md){
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            width: 158px;
        }
        // @include mq($until: sm){
        //     width: 25vw;
        // }

    }
    &__nav{
        width: 100%;
        position: absolute;
        top: 8vw;
        transition: .3s ease-in-out;
        @include mq($until: xl){
            top: 10vw;
        }
        @include mq($until: lg){

            top: 12vw;
        }
        @include mq($until: md){
            top: 0;
        }
    }
    &__menuList{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        @include mq($until: md){
            margin-top: 0; 
        }
    }
    &__menuItem{
        margin: 0;
        padding: 0;
        &::before{
            display: none;
        }
    }
    &__menuLink{
        transition: .3s ease-in-out;
        color: $color-main;
        font-size: rem(21px);
        &:active, &--active, &--open{
            color: $color-main;
        }
        &:hover{
            color: $color-extra;
        }
    }
    &__hamburger{
        @include mq($until: md){
            margin-right: 20px;
            margin-top: 17px;   
        }
        
    }
}


.top-bar-wave{
    z-index: 1;
    position: absolute;
    width: 100%;
    height: auto;
    background-color: transparent;
    fill: $color-main;
    @include mq($until: md) {
        transition: .3s;
        right: 0;
        width: 100%;
        transform-origin: top center;    
        top: 0;
    }
    @include mq($until: sm) {
        width: auto;
        height: 70px;
    }
}

.subsite{
    .header{
        &__nav{
            top: 1vw;
            z-index: 2;
            @include mq($until: md) {
                top: 0;
            }
        }
        &__menuLink{
            color: $color-font-light;
            &:active, &--active, &--open{
                color: $color-font-light;
            }
            &:hover{
                color: $color-extra;
            }
        }
        &__logo{
            top: 4vw;
            @include mq($until: md) {
                top: auto;
            }
        }
        &.sticky{
            .header__nav{
                top: 1vw;
                @include mq($until: md){
                    top: 0;
                }
            }
        }
    }
}
.list-page{
    display: grid;
    grid-gap: 80px;
    @include mq($until: md){
        grid-gap: 50px;
    }
    &__heading{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 120px;
        @include mq($until: lg){
            grid-gap: 90px;

        }
        @include mq($until: md){
            grid-gap: 50px;
            grid-template-columns: 1fr;
        }
    }
    &__title-wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
    &__title{
        margin-bottom: 30px;
        margin-right: 30px;
    }
    &__button{
        text-transform: none;
        padding: 15px 30px;
        font-size: rem(23px);
        margin-bottom: 30px;
        border: 1px solid $color-extra;
        @include mq($until: sm){
            padding: 10px auto;
        }
        @include mq($until: xs){
            flex-basis: 100%;
        }
        &:hover{
            border: 1px solid $color-main;
        }
    }
}
.info-box{
    padding-top: 50px;
    padding-bottom: 50px;
    overflow: hidden;
    &__card{
        display: grid;
        grid-gap: 35px;
        justify-content: center;
        align-items: flex-start;
        margin: 100px 0;
        &:first-child{
            margin-top: 0;
        }
        &:last-child{
            margin-bottom: 0;
        }
        @include mq($until: md) {
            margin: 70px 0;
        }
        @include mq($until: sm) {
            margin: 100px 0;
        }
        img{
            position: relative;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            // max-height: 100%;
            max-width: 400px;
            @include mq($until: lg) {
                max-width: 300px;
            }
            @include mq($until: md) {
                max-width: 200px;
            }
        }
        &--image-left{
            grid-template-areas: "image content content";
            grid-template-columns: auto 1fr 1fr;
            @include mq($until: lg) {
                grid-template-areas: "image content content";
            }
            @include mq($until: md) {
                grid-template-columns: 0 1fr 1fr;
                grid-gap: 0;
                img{
                    left: 0;
                    opacity: .2;
                    max-height: 300px;
                    max-width: 300px;
                    z-index: -1;
                    transform: translateX(50%);
                }
            }
        }
        &--image-right{
            grid-template-columns: 1fr 1fr auto;
            grid-template-areas: "content content image";
            @include mq($until: lg) {
                grid-template-areas: "content content image";
            }
            @include mq($until: md) {
                grid-template-columns: 1fr 1fr 0 ;
                grid-gap: 0;
                img{
                    right: 0;
                    left: auto;
                    opacity: .2;
                    transform: translateX(-50%);
                    max-height: 300px;
                    max-width: 300px;
                    z-index: -1;
                }
            }
        }

    }
    &__image-wrapper{
        grid-area: image;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        max-height: 300px;
        @include mq($until: sm) {
            overflow: visible;
        }
    }
    &__content-wrapper{
        grid-area: content;
        display: flex;
        flex-direction: column;
    }
    &__title{
        font-size: rem(31px);
        max-width: 500px;
        margin-bottom: 30px;
        font-weight: $fw-bold;
    }
    &__bottom-wrapper{
        margin-top: 30px;
        display: flex;
        align-items: center;
        @include mq($until: sm) {
            justify-content: flex-end;
            flex-wrap: wrap;
        }
        & > a{
            margin-left: auto;
        }
    }
    &__extra-info{
        font-size: rem(26px);
        max-width: 500px;
        font-weight: $fw-bold;
    }
}
.top-section{
    height: calc(100vh - 4vw);
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: 50% 50%;
    overflow: hidden;
    margin-top: 4vw;
    @include mq($until: lg) {
        margin-top: 0;
    }
}

.hero{
    height: auto;
    border: 25px solid $color-font-light;
    margin-top: 100px;
    display: grid;
    grid-template-columns: 70% 30%;
    position: relative;
    min-height: 450px;
    background-color: rgba($color: #ffff, $alpha: 0.4);
    @include mq($until: xl) {
        min-height: 400px;
        grid-template-columns: 60% 40%;
    }
    @include mq($until: lg) {
        grid-template-columns: 60% 40%;
    }
    @include mq($until: md) {
        background-color: rgba($color: $color-bg, $alpha: 0.4);
    }
    @include mq($until: sm) {
        min-height: 300px;
        border: none;
        grid-template-columns: 70% 30%;
        position: relative;
        background-color: rgba($color: $color-bg, $alpha: 0.8);
        &:before{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            content: "";
            height: calc(100% + 40px);
            width: 100vw;
            background-color: rgba($color: #ffff, $alpha: 0.4);
            z-index: 0;
            
        }
    }
    @include mq($until: xs) {
        grid-template-columns: 90% 10%;
    }
    &__left-col{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        @include mq($until: sm) {
            z-index: 1;
        }
    }
    &__heading-wrapper{
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex-basis: 70%;
        padding: 30px 0 0 30px;
        @include mq($until: md) {
            padding: 20px 0 0 20px;
        }
        @include mq($until: sm) {
            padding: 0;
        }
    }
    &__heading{
        font-size: rem(58px);
        line-height: rem(58px);
        @include mq($until: xl) {
            font-size: rem(48px);
            line-height: rem(48px);
        }
        @include mq($until: md) {
            font-size: rem(45px);
            line-height: rem(45px);
        }
        @include mq($until: sm) {
            font-size: rem(35px);
            line-height: rem(35px);
        }
    }
    &__addres{
        font-size: rem(23px);
        @include mq($until: sm) {
            display: block;
            margin-bottom: 20px;
        }
    }
    &__heading-bottom{
        display: flex;
        flex-basis: 30%;
        padding: 60px 0 30px 0;
        @include mq($until: sm) {
            padding-top: 30px;
        }
        
    }
    &__image-small-wrapper{
        position: relative;
        flex-basis: 50%;
        @include mq($until: md) {
            display: none;
        }
    }
    &__image-small{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 50%;
        top: 50%;
        transform: translate(30%, -30%);
        width: calc(100% + 50px);

    }
    &__button-wrapper{
        flex-basis: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @include mq($until: md) {
            flex-basis: auto;
            max-width: 250px;
            padding: 10px 0 0 10px;
        }
        @include mq($until: sm) {
            padding: 0;
        }
    }
    &__button-link{
        display: grid;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        border: 2px solid $color-main;
    }
    &__right-col{
        position: relative;
        img{
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 140%;
            max-width: none;
            @include mq($until: xl) {
                left: 15%;
                width: 105%;
            }
            @include mq($until: lg) {
                width: 120%;
            }
            @include mq($until: md) {
                width: 180%;
                left: -20%;
            }
            @include mq($until: sm) {
                left: auto;
                transform: translate(50%, -50%);
                right: -20%;
                width: auto;
                height: 180%;
                opacity: 0.6;
            }
        }
        @include mq($until: sm) {
            z-index: 0;
        }
    }

}


.subsite-top{
    height: 540px;
    width: 100%;
    background-size: cover;
    background-position: center center;
    @include mq($until: xl) {
        height: 440px;
    }
    @include mq($until: lg) {
        height: 360px;
    }
    @include mq($until: md) {
        position: relative;
        height: 260px;
    }
    &--empty{
        padding-top: 10vw;
        position: relative;
        &:before{
            content: "";
            top: 0;
            left: 0;
            position: absolute;
            height: 10vw;
            width: 100%;
            background-color: $color-bg;
            @include mq($until: sm) {
                padding-top: 15vw;
            }
        }
        @include mq($until: sm) {
            padding-top: 15vw;
        }
    }
}
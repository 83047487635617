.subsite-content{
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: $color-bg;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    @include mq($until: sm) {
        padding-top: 50px;
    }
    .col-left{
        padding-top: 40px;
        p{
            font-size: rem(20px);
            margin-bottom: 25px;
            &:last-of-type{
                margin-bottom: 0;
            }
        }
    }
    .gallery{
        margin-top: 60px;
        width: 100%
    }
    &__page-title{
        font-size: rem(36px);
        margin-bottom: 48px;
    }
}